<template>
  <div>
    <h3 class="center">Путь бариски</h3>
    <div class="heapR">
      <div class="heap-block local25">
        <PeopleSelector
          label="Бариска"
          :filter="people_filter"
          :id_peopleIn="id_people"
          @change="id_people = $event"
        />
      </div>

      <!-- <div class="margins1">
        <StatusSelector
          label="Критерий отбора"
          labelPosition="left"
          @change="people_filter = $event"
        />
      </div> -->
    </div>

    <br />
    <div v-if="loading">
      <Loader />
    </div>

    <div v-else class="body-wide">
      <table class="t_table">
        <tr class="noselect">
          <th style="width: 5%">N</th>
          <th>Дата</th>
          <th>Детали</th>
          <th style="width: 60%">Камент</th>
        </tr>
        <!-- data rows -->
        <tr v-for="(row, y) in filter(data)" :key="y">
          <td class="user" @click="windowOpen(row.id)">{{ y + 1 }}</td>
          <!-- <td
            v-for="(c, i) in columnList"
            :class="{ hide: columnSelected.indexOf(c) == -1 }"
            :key="i"
            :ref="y == 0 ? 'cell' + c : null"
          > -->

          <td class="user" @click="windowOpen(row.id)">{{ dateFormatVarious(row.date) }}</td>
          <td class="user" @click="windowOpen(row.id)">
            <b>{{ HISTORY_EVENT_CAPTION[row.event] }}</b>
            <table class="inner">
              <tr v-if="row.id_trainer">
                <td>Тренер:</td>
                <td>{{ row.nickname_trainer }}</td>
              </tr>

              <div v-if="row.attestation_name">
                <tr>
                  <td>Предмет:</td>
                  <td>{{ row.attestation_name }}</td>
                </tr>

                <tr v-if="row.id_attestator0">
                  <td>Аттестатор1:</td>
                  <td>{{ row.nickname_attestator0 }}</td>
                </tr>

                <tr v-if="row.id_attestator1">
                  <td>Аттестатор2:</td>
                  <td>{{ row.nickname_attestator1 }}</td>
                </tr>
                <tr v-if="row.id_attestator2">
                  <td>Аттестатор3:</td>
                  <td>{{ row.nickname_attestator2 }}</td>
                </tr>
              </div>
            </table>
          </td>
          <td>
            <div class="left">
              <span v-html="parseHtml(row.comment)"></span>
            </div>
          </td>
          <!-- <span @click="enlarge(c, row[c])" v-html="decorateField(c, row[c])"></span> -->
        </tr>
        <template v-if="filter(data).length == 0">
          <tr>
            <td colspan="300" class="center">нет данных по выбранным параметрам</td>
          </tr>
        </template>
        <tr>
          <td
            class="user"
            colspan="300"
            style="border: none; background-color: white; padding-top: 1rem"
          >
            <div @click="windowOpen(0)">добавить событие</div>
          </td>
        </tr>
      </table>
    </div>

    <div v-if="enlargeFlag" class="modalTurn" @click="close()">
      <div style="display: flex">
        <img :src="imagePhoto" alt="фоточка" style="object-fit: contain; height: 100vh" />
      </div>
    </div>

    <BariskaHistoryWindow @hide="windowClose" :showFlag="showFlag" :itemIn="item" />
  </div>
</template>

<style lang="scss" scoped>
td.user:hover {
  cursor: pointer;
  text-decoration: underline;
}

.local25 {
  width: 25%;
}

.inner td {
  border: none;
}
</style>
<script>
import Loader from "@/components/Loader.vue";
import PeopleSelector from "@/components/PeopleSelector.vue";
// import StatusSelector from "@/components/StatusSelector.vue";
import BariskaHistoryWindow from "@/components/BariskaHistoryWindow.vue";
import { request } from "@/components-js/requestSrv";
import { decorateField } from "@/components-js/decorateField.js";
import { dateFormatVarious } from "@/components-js/dateFormat";
import { sortCol } from "@/components-js/sortCol.js";
import { PHOTO_ROUTE } from "@/config/settings";
import { HISTORY_EVENT_VALUE, HISTORY_EVENT_CAPTION } from "@/config/settings";

import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { Loader, /* StatusSelector, */ PeopleSelector, BariskaHistoryWindow },
  data() {
    return {
      loading: false,

      id_people: null,
      data: [],
      // type of people in people list
      people_filter: "work",

      HISTORY_EVENT_CAPTION: HISTORY_EVENT_CAPTION,
      HISTORY_EVENT_VALUE: HISTORY_EVENT_VALUE,

      oFlag: 1,
      item: null,
      showFlag: false,
      // all columns to be shown
      columnList: [],
      // all columns captions
      columnListCaption: [],

      enlargeFlag: 0,
      imagePhoto: "",
    };
  },

  watch: {
    id_people: function() {
      this.tableDataRetrieve();
    },
  },

  async mounted() {
    let tmp = loStorage.getObjectProp(this.$route.path, "id_people");
    if (tmp) this.id_people = tmp;

    this.tableDataRetrieve();
  },

  methods: {
    parseHtml(s) {
      return s.replaceAll("\n", "<br/>");
    },

    dateFormatVarious(d, w) {
      return dateFormatVarious(d, w);
    },

    decorateField(field, value) {
      return decorateField(field, value);
    },

    async tableDataRetrieve() {
      this.loading = true;
      this.data = await request(
        "/api/history/getsingle",
        "POST",
        { id_people: this.id_people },
        this.$route.path
      );

      this.loading = false;
    },

    // rolesOut(rolesArr) {
    //   let res = "";
    //   for (let i in rolesArr) {
    //     res += rolesArr[i];
    //     res += i < rolesArr.length - 1 ? ", " : "";
    //   }
    //   return res;
    // },

    filter(data) {
      if (!data) return false;
      return data.filter(i => i.status == this.people_filer);
    },

    sort(col, arr, refs, oFlag) {
      this.oFlag = sortCol(col, arr, refs, oFlag);
    },

    enlarge(field, value) {
      if (field != "photo") return;
      this.imagePhoto = `${PHOTO_ROUTE}${value}`;
      this.enlargeFlag = value;
    },

    close() {
      this.enlargeFlag = 0;
    },

    windowOpen(id) {
      // if item selected
      if (id) {
        const pos = this.data.findIndex(i => i.id == id);
        this.item = this.data[pos];
      } else {
        this.item = {};
        this.item.id_people = this.id_people;
      }
      // show window at least
      this.showFlag = true;
    },

    async windowClose(item) {
      this.showFlag = false;
      if (!item.id) return;
      await this.tableDataRetrieve();
    },
  },
};
</script>
