import { TELEGRAM_URL_PREFIX, PHOTO_ROUTE } from "@/config/settings";
import { numFormat } from "@/components-js/numberFormat";

function decorateField(field, value) {
  if (field == "saldo") return numFormat(value);

  if (field == "gender") {
    switch (value) {
      case "m":
        return "\u{1f468}";
      // break;
      case "f":
        return "\u{1f469}";
      // break;
      case "l":
        return "\u{1f46D}";
      // break;
      case "g":
        return "\u{1f46C}";
      case "t":
        return "\u{26a7}";
      // break;
    }
  }

  if (field == "auto_table") {
    switch (value) {
      case 1:
        return "\u{1f170}";
      default:
        return null;
    }
  }

  if (field == "smoke_status") {
    switch (value) {
      case "smoke":
        return "\u{1F6AC}";
      case "agreement":
        return "\u{1F6AD}";
      case "other":
        return "\u{2753}";
      case "none":
        return "-";
      default:
        return "?";
    }
  }

  if (field == "telegram" && value) {
    return `<a href="${TELEGRAM_URL_PREFIX}${value}" target="_blank" class="telegram">${value}</a>`;
  }

  if (field == "photo") {
    if (value) return `<img src="${PHOTO_ROUTE}${value}" class="small"/>`;
    else return `<img class="small"/>`;
  }

  return value;
}

export { decorateField };
