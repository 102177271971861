<template>
  <div v-if="showFlag">
    <section class="modalTurn">
      <div class="modalTurn-content">
        <div class="modalWin">
          <h3 v-if="item.id">Править событие</h3>
          <h3 v-else>Добавить событие</h3>

          <table style="width: 90%">
            <tr>
              <td class="R">Дата</td>
              <td class="L">
                <input type="date" v-model="item.date" ref="date" />
              </td>
            </tr>

            <tr>
              <td class="R">Событие</td>
              <td class="L">
                <select v-model="item.event" ref="event" @change="changeF(item.event)">
                  <option v-for="el in HISTORY_EVENT_VALUE" :value="el" :key="el">
                    {{ HISTORY_EVENT_CAPTION[el] }}
                  </option>
                </select>
              </td>
            </tr>

            <tr v-if="item.event == HISTORY_EVENT_VALUE[0]">
              <td class="R">Тренер</td>
              <td class="L" ref="trainer">
                <PeopleSelector
                  label=""
                  :filter="'work'"
                  :id_peopleIn="item.id_trainer"
                  @change="item.id_trainer = $event"
                />
              </td>
            </tr>

            <tr v-if="item.event == HISTORY_EVENT_VALUE[1]">
              <td class="R">Аттестатор1</td>
              <td class="L" ref="attestator0">
                <PeopleSelector
                  label=""
                  :filter="'work'"
                  :id_peopleIn="item.id_attestator0"
                  @change="item.id_attestator0 = $event"
                />
              </td>
            </tr>

            <tr v-if="item.event == HISTORY_EVENT_VALUE[1]">
              <td class="R">Аттестатор2</td>
              <td class="L" ref="attestator1">
                <PeopleSelector
                  label=""
                  :filter="'work'"
                  :id_peopleIn="item.id_attestator1"
                  @change="item.id_attestator1 = $event"
                />
              </td>
            </tr>

            <tr v-if="item.event == HISTORY_EVENT_VALUE[1]">
              <td class="R">Аттестатор3</td>
              <td class="L" ref="attestator2">
                <PeopleSelector
                  label=""
                  :filter="'work'"
                  :id_peopleIn="item.id_attestator2"
                  @change="item.id_attestator2 = $event"
                />
              </td>
            </tr>

            <tr v-if="item.event == HISTORY_EVENT_VALUE[1]">
              <td class="R">Предмет</td>
              <td class="L" ref="attestation_type">
                <AttestationTypeSelector
                  label=""
                  :id_itemIn="item.attestation_type"
                  @change="item.attestation_type = $event"
                />
              </td>
            </tr>

            <tr>
              <td class="R">Камент</td>
              <td class="L">
                <textarea
                  v-model.trim="item.comment"
                  placeholder="Комментарий обязателен"
                  ref="comment"
                  style="width: 100%"
                />
              </td>
            </tr>
          </table>

          <div class="err">{{ errMessage }}&nbsp;</div>
          <br />
          <div class="col-revers">
            <button ref="applyButton" v-on:click.prevent="Apply()" class="btn">
              {{ item.id ? "Применить" : "Добавить" }}
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button v-on:click.prevent="Cancel()" class="btn cancel">Отмена</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button v-if="item.id" v-on:click.prevent="Delete()" class="btn warn">
              Удалить
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss" scoped>
div {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
.col-revers {
  display: flex;
  flex-direction: row-reverse;
}
td.R {
  text-align: right;
}
td.L {
  text-align: left;
}
table {
  border-spacing: 0.4rem;
}
</style>
<script>
import { request } from "@/components-js/requestSrv";
import { dateFormatJS } from "@/components-js/dateFormat";
import PeopleSelector from "@/components/PeopleSelector.vue";
import AttestationTypeSelector from "@/components/AttestationTypeSelector.vue";
import { HISTORY_EVENT_VALUE, HISTORY_EVENT_CAPTION } from "@/config/settings";
// import { ATTESTATION_VALUE, ATTESTATION_CAPTION } from "@/config/settings";

export default {
  name: "BariskaHistoryWindow",
  props: {
    showFlag: Boolean,
    itemIn: {},
  },
  components: { PeopleSelector, AttestationTypeSelector },
  data() {
    return {
      errMessage: null,
      item: {},
      HISTORY_EVENT_CAPTION: HISTORY_EVENT_CAPTION,
      HISTORY_EVENT_VALUE: HISTORY_EVENT_VALUE,
    };
  },

  watch: {
    showFlag: function (newValue) {
      // window appeared
      if (newValue) {
        document.addEventListener("keyup", this.keyPressHandler);

        if (this.itemIn.id) {
          // create copy
          for (let i in this.itemIn) {
            this.item[i] = this.itemIn[i];
          }
        } else {
          this.item = new Object();
          this.initDefault();
        }
      } else document.removeEventListener("keyup", this.keyPressHandler);
    },
  },

  methods: {
    changeF() {
      this.$forceUpdate();
    },

    initDefault() {
      this.item.id = null;
      // set default values and create
      const d = new Date();
      this.item.id_people = this.itemIn.id_people;
      this.item.date = dateFormatJS(d);
      this.item.event = HISTORY_EVENT_VALUE[0];
      this.item.comment = null;
      this.item.id_trainer = null;
      this.item.id_attestator0 = null;
      this.item.id_attestator1 = null;
      this.item.id_attestator2 = null;
      this.item.attestation_type = null;
    },

    // setType(ref, type) {
    //   this.$refs[ref].setAttribute("type", type);
    // },

    keyPressHandler(evt) {
      if (evt.code == "Escape") this.Cancel();
    },

    async Apply() {
      if (!this.item.comment || !this.item.comment.length) {
        this.$refs.comment.classList.add("warn-border");
        setTimeout(() => this.$refs.comment.classList.remove("warn-border"), 300);
        return;
      }

      // if learn, then clear attestation data
      if (this.item.event == HISTORY_EVENT_VALUE[0]) {
        // trainer needed
        if (!this.item.id_trainer) {
          this.$refs.trainer.classList.add("warn-border");
          setTimeout(() => this.$refs.trainer.classList.remove("warn-border"), 300);
          return;
        }
        // clear attestation data
        this.item.attestators = null;
        this.item.attestation_type = null;
      }

      // if attestation, then clear learn data
      if (this.item.event == HISTORY_EVENT_VALUE[1]) {
        // attestator needed
        if (!this.item.id_attestator0) {
          this.$refs.attestator0.classList.add("warn-border");
          setTimeout(() => this.$refs.attestator0.classList.remove("warn-border"), 300);
          return;
        }
        // attestation_type needed
        if (!this.item.attestation_type) {
          this.$refs.attestation_type.classList.add("warn-border");
          setTimeout(() => this.$refs.attestation_type.classList.remove("warn-border"), 300);
          return;
        }
        // form attestors list
        const temp = [];
        temp.push(parseInt(this.item.id_attestator0));
        temp.push(parseInt(this.item.id_attestator1));
        temp.push(parseInt(this.item.id_attestator2));
        this.item.attestators = JSON.stringify(temp);
        // clear learn data
        this.item.id_trainer = null;
      }

      // clear additional data for other events
      if (
        this.item.event != HISTORY_EVENT_VALUE[0] &&
        this.item.event != HISTORY_EVENT_VALUE[1]
      ) {
        this.item.attestators = null;
        this.item.attestation_type = null;
        this.item.id_trainer = null;
      }

      if (this.item.id) {
        const res = await request("/api/history/update", "POST", this.item);
        if (res.affectedRows) this.windowClose();
        else {
          this.$refs.applyButton.classList.add("warn");
          setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
          return;
        }
      } else {
        const res = await request("/api/history/insert", "POST", this.item);
        if (res.affectedRows == 1 && res.insertId) {
          this.item.id = res.insertId;
          this.windowClose();
        }
      }
    },

    async Delete() {
      if (!confirm("Удалить событие?")) return;

      const res = await request("/api/history/delete", "DELETE", {
        id: this.item.id,
      });
      if (res.affectedRows) {
        this.item.id *= -1;
        this.windowClose();
      } else {
        this.errMessage = "ошибка удаления";
        setTimeout(() => (this.errMessage = null), 1500);
      }
    },

    Cancel() {
      this.item.id = 0;
      this.windowClose();
    },

    windowClose() {
      this.$emit("hide", this.item);
    },
  },
};
</script>
