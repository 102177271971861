<template>
  <div class="high" @click="$refs.att.focus()">
    {{ label }}
    <select @change="$emit('change', $event.target.value)" ref="att">
      <option v-for="el in list" :value="el.id" :key="el.id">
        {{ el.attestation_name }}
      </option>
    </select>
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";

export default {
  name: "AttestationTypeSelector",
  props: {
    label: String,
    id_itemIn: null,
    // filter: String,
  },

  data: function () {
    return { list: null };
  },

  watch: {
    id_itemIn: function (newVal) {
      this.$refs.att.value = newVal;
      console.log(newVal);
    },
  },

  // async beforeMount() {
  //   this.list = await request("/api/history/attestationlist");
  // },

  async mounted() {
    this.list = await request("/api/history/attestationlist");
    this.$refs.att.value = this.id_itemIn;
  },

  updated() {
    // when element fully loaded from server
    this.$refs.att.value = this.id_itemIn;
  },
};
</script>
